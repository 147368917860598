<template>
	<div class="wrapper">
		<v-container
			class="pa-0 px-5"
			fluid
		>
			<v-col
				cols="12"
				align="center"
				class="login layout_align pa-0"
			>
				<v-col
					cols="12"
					align="center"
					class="pa-0"
				>
					<h2 class="content_title">휴면 계정 해제 완료</h2>
				</v-col>

				<v-col
					cols="12"
					class="pa-0"
				>
					<v-col
						cols="12"
						class="pa-0"
					>
						<div class="msg_box mb-3">
							회원님의 <strong> 휴면 계정이 정상적으로 해제</strong> 되었습니다.<br />
							확인 후 로그인 해주시기 바랍니다.
						</div>
					</v-col>

					<v-col class="px-0">
						<CommonButtonsButton01
							name="로그인 하기"
							color="#00582c"
							height="50"
							class-name="bold font_16 mb-2"
							rounded
							@click="$router.push('/login')"
						/>
					</v-col>
				</v-col>
			</v-col>
		</v-container>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
	name: 'LoginAnswerActiveInfo',
	data: () => ({}),
	computed: {
		...mapGetters(['AUTH_GET_ACTIVE_INFO']),
	},
	methods: {
		...mapMutations(['AUTH_MU_ACTIVE_INFO']),
	},
}
</script>

<style lang="scss" scoped>
.login {
	max-width: 340px !important;
}
.notice {
	font-family: 'NotoSansKR-Regular' !important;
	font-size: 12px !important;
	color: #5f6061 !important;
	word-break: keep-all;
}
</style>
